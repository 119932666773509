import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(async () => {
  const { isLoading, authenticated, onfidoChecked } = toRefs(useAuthStore());

  if (!isLoading.value && !authenticated.value) {
    return navigateTo({ path: '/login' });
  }

  if (onfidoChecked.value) {
    return navigateTo({ path: '/mijn-profiel' });
  }
});
